import { openDB } from 'idb';

const DB_NAME = "imageCache";
const STORE_NAME = "images";
const EXPIRY_DAYS = 30; // Set expiration in days

const dbPromise = openDB(DB_NAME, 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains(STORE_NAME)) {
      db.createObjectStore(STORE_NAME);
    }
  },
});

// Save image with timestamp
export const saveImageToDB = async (key, base64Image) => {
  try {
    const db = await dbPromise;
    const timestamp = Date.now(); // Store current timestamp
    await db.put(STORE_NAME, { base64Image, timestamp }, key);
  } catch (error) {
    console.error("Failed to save image:", error);
  }
};

// Get image from IndexedDB
export const getImageFromDB = async (key) => {
  try {
    const db = await dbPromise;
    const entry = await db.get(STORE_NAME, key);

    if (!entry) return null;

    // Check if image is expired
    const expiryTime = EXPIRY_DAYS * 24 * 60 * 60 * 1000; // Convert days to milliseconds
    if (Date.now() - entry.timestamp > expiryTime) {
      console.log(`Image expired: ${key}, deleting...`);
      await db.delete(STORE_NAME, key); // Delete expired image
      return null;
    }

    return entry.base64Image;
  } catch (error) {
    console.error("Failed to retrieve image:", error);
    return null;
  }
};

// Clear all images from IndexedDB
export const clearImageCache = async () => {
  try {
    const db = await dbPromise;
    await db.clear(STORE_NAME);
  } catch (error) {
    console.error("Failed to clear image cache:", error);
  }
};

// Delete old images automatically
export const cleanOldImages = async () => {
  try {
    const db = await dbPromise;
    const keys = await db.getAllKeys(STORE_NAME);

    for (const key of keys) {
      await getImageFromDB(key); // This will automatically remove expired images
    }
  } catch (error) {
    console.error("Error cleaning old images:", error);
  }
};
