// indexed db storage
import { useState, useEffect } from "react";
import { getImageFromDB, saveImageToDB, cleanOldImages } from "../utility/indexedDB";

const useImageCacheIndexedDb = (imageUrl) => {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const fetchImage = async () => {
      setLoading(true);

      // Clean old images first
      // await cleanOldImages();

      // Check if image exists in IndexedDB
      const cachedImage = await getImageFromDB(imageUrl);
      if (cachedImage) {
        setImage(cachedImage);
        setLoading(false);
        return;
      }

      // Fetch from network if not cached
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = async () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const base64Image = canvas.toDataURL("image/png");

        // Save to IndexedDB with timestamp
        await saveImageToDB(imageUrl, base64Image);

        if (isMounted) {
          setImage(base64Image);
          setLoading(false);
        }
      };
      img.onerror = () => setLoading(false);
      img.src = imageUrl;
    };

    fetchImage();

    return () => {
      isMounted = false;
    };
  }, [imageUrl]);

  return { image, loading };
};

export default useImageCacheIndexedDb;
