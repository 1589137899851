// local storage
import { useState, useEffect } from 'react';

const imageCache = {};

const useImageCacheLocalStorage = (imageUrl) => {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const cachedImage = localStorage.getItem(imageUrl);
    if (cachedImage) {
      setImage(cachedImage);
      setLoading(false);
    } else {
      const img = new Image();
      img.onload = () => {
        localStorage.setItem(imageUrl, img.src);
        setImage(img.src);
        setLoading(false);
      };
      img.src = imageUrl;
    }
  }, [imageUrl]);

  return { image, loading };
};

export default useImageCacheLocalStorage;