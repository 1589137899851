import { useEffect, useState } from "react";
import CustomImage from "./CustomImage";

const UploadImage = ({selectedFile,handleChange}) => {
  
  return (
    <>
      <h3>Your Photo</h3>
      <div className="mb-3">
        {selectedFile && (
          <CustomImage className="profile__image" state={{image:selectedFile}} size={"5rem"}/>
        )}
        <input type="file" id="file" accept="image/*" onChange={handleChange('image')} />
      </div>
    </>
  );
}

export default UploadImage;
