import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useNavigate,
  useLocation
} from "react-router-dom";

import Cards from "../components/Cards";
import NavBar from "../components/Navigation/NavBar";
import { getPaymentRequestStatus } from "../redux/slices/paymentSlice";
import { resetUserListAction, usersAdminAction } from "../redux/slices/usrSlice";

import { searchAction, usrAction } from "../redux/slices/usrSlice";
import { setHeader } from "../redux/slices/headerSlice";

import CustomSearch from "../components/CustomSearch";
import { getConfig, getConstant, getGeoLocation, handleHeader } from "../services/functions";
import { BASE_URL, HOME_SEARCH_PATH, SUBSCRIPTION_DETAILS_PATH } from "../utility/path";
import HomeCategories from "../components/HomeCategories";
import SearchModel from '../components/SearchModel';
import CustomLogo from "../components/CustomLogo";
import DynamicPlaceholder from "../components/DynamicPlaceholder";
import axios from "axios";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";

const UserManagment = ({ }) => {
  //const { users } = useLoaderData();

  const categoriesData = useSelector(state => state?.categories?.data);
  const authData = useSelector(state => state?.auth?.data);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(5);
  const [dataList, setDataList] = useState([]);
  const [state, setState] = useState({ mobile: '', pincode:'', category: '' });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false);

  const loadData = async () => {
    try {
      setLoading(true);
      const config = getConfig();
      const resp  = await axios.post(
        `${BASE_URL}/api/users/lista`,
        {limit,skip,...state},
        config
      );

      if(resp?.data?.user?.length > 0){
        setDataList((prevList) => [...prevList, ...resp?.data?.user]); // Append new data
      }else{
        setLimit((prevSkip) => 0); // Update skip based on fetched data length
      }
      setSkip((prevSkip) => prevSkip + resp?.data?.user?.length || 0); // Update skip based on fetched data length
      setLoading(false);

    } catch (error) {
      console.error("Error loading data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setDataList([]);
    loadData();
  }, []);


  const openSearchModal = () => {
    const modalElement = document.getElementById('searchModal');
    if (modalElement) {
      //const modal = bootstrap.Modal.getInstance(modalElement); // For Bootstrap 5
      //modal.hide();
      $(modalElement).modal('show'); // For Bootstrap 4

      const modalElementHeader = document.getElementById('header');
      modalElementHeader.classList.add('hidden');
    }
  };

  const closeSearchModal = async () => {
    const modalElementHeader = document.getElementById('header');
    modalElementHeader.classList.remove('hidden');

    const modalElement = document.getElementById('searchModal'); // Replace with your modal's ID
    if (modalElement) {
      //const modal = bootstrap.Modal.getInstance(modalElement); // For Bootstrap 5
      //modal.hide();
      $(modalElement).modal('hide'); // For Bootstrap 4
    }
  };

  const findByMobile = (input) => async (e) => {
    e.preventDefault();

    if(!state?.mobile && !state?.pincode && !state?.category){
      return;
    }
    
    loadDataForMobileSearch();
  }

  const handleChange = input => e => {
    setState({ ...state, [input]: e.target.value });
  }

  const loadDataForMobileSearch = async (e) => {
    try {
      const config = getConfig();
      const resp  = await axios.post(
        `${BASE_URL}/api/users/lista`,
        {limit:5,skip:0,...state},
        config
      );
      setDataList(resp?.data?.user);
      setSkip((prevSkip) => prevSkip + resp?.data?.user?.length || 0); // Update skip based on fetched data length

    } catch (error) {
      console.error("Error loading data:", error);
    }
  };

  const resetData = async (e) => {
    try {
      setDataList([]);
      setSkip((prevSkip) => 0);
      setLimit(5);
      setState({mobile: '', pincode: '', category:''})
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };

  return (
    <>
      <NavBar />
      <div className="main-container">
        <div className="heading">
          <CustomLogo />
          <div // Handle click event
            data-toggle="modal" data-target="#searchModal">
            {/* <CustomSearch categoriesData={[]} /> */}
            <div className="row">
              {/* <div className="col-md-6 offset-md-3">
                <div className="form-group input-group mb-3">
                  <DynamicPlaceholder data={categoriesData} handleClick={openSearchModal} className={"form-control custom-search-input"} autoComplete={"off"} />
                </div>
              </div> */}

              <div className="col-md-6 offset-md-3 d-flex">
                <div className="flex-grow-1"><CustomInput id="mobileId" type="number" placeholder="Enter mobile" handleChange={handleChange} name="mobile" value={state?.mobile}/></div>
                <button className="btn btn-outline-primary btn-sm m-2" onClick={findByMobile("mobile")} disabled={!state?.mobile || loading}>Find by Mobile</button>
              </div>

              <div className="col-md-6 offset-md-3 d-flex">
                <div className="flex-grow-1"><CustomInput id="pincodeId" type="number" placeholder="Enter pincode" handleChange={handleChange} name="pincode" value={state?.pincode}/></div>
                <button className="btn btn-outline-primary btn-sm m-2" onClick={findByMobile("pincode")} disabled={!state?.pincode || loading}>Find by Pincode</button>
              </div>

              <div className="col-md-6 offset-md-3 d-flex">
                <div className="flex-grow-1"><CustomSelect title="" handleChange={handleChange} name="category" defaultOptionValue="Select category" data={categoriesData} state={state} isDisable={false}/></div>
                <button className="btn btn-outline-primary btn-sm m-2" onClick={findByMobile("category")} disabled={!state?.category || loading}>Find by Category</button>
              </div>

              <div className="col-md-6 offset-md-3">
                <button className="btn btn-outline-primary btn-sm" onClick={resetData}>Reset</button>
              </div>

            </div>
          </div>
        </div>

        <>
          {dataList?.length}
          <Cards users={dataList} />
          <button className="btn btn-outline-primary btn-sm m-2" onClick={loadData} disabled={limit < 1 || loading}>view more</button>
        </>

      </div>
    </>
  );
}

export default UserManagment;

export async function loaderLocation() {
  const position = await getGeoLocation();
  const data = {
    latitude: position.coords.latitude,
    longitude: position.coords.longitude,
    altitude: position.coords.altitude,
    accuracy: position.coords.accuracy,
    altitudeAccuracy: position.coords.altitudeAccuracy,
    heading: position.coords.heading,
    speed: position.coords.speed,
    timestamp: position.timestamp
  }
  return data;
}
