import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {Provider, useDispatch} from 'react-redux';
import store from './redux/store/store';

import {callGetGeoLocation, getDeviceId, getGeoLocation, onresize} from './services/functions';
import Spinner from './pages/Spinner';
import Alert from './components/Alert';
import Main from './Main';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Render React DOM
const renderReactDom = async () => {
  root.render(
  <Provider store={store}>
      <Main />
  </Provider>
);
};

const registerServiceWorker = async () =>{
  console.log("registerServiceWorker");
  if('serviceWorker' in navigator){
    console.log('service worker is available')
    navigator.serviceWorker.register('/sw.js')
    .then(function(){
      console.log('service worker registered')
    });
  }
}

const requestLocationPermission = async () => {
  if (cordova.plugins.diagnostic) {
    console.log("Checking location permission...");

    // Check current location permission status
    // cordova.plugins.diagnostic.getLocationAuthorizationStatus((status) => {
    //   if (status === cordova.plugins.diagnostic.permissionStatus.GRANTED) {
    //     console.log("Location permission already granted.");
    //     callGetGeoLocation();
    //   } else if (status === cordova.plugins.diagnostic.permissionStatus.NOT_REQUESTED) {
    //     console.log("Requesting location permission...");
    //     cordova.plugins.diagnostic.requestLocationAuthorization(
    //       (newStatus) => {
    //         if (newStatus === cordova.plugins.diagnostic.permissionStatus.GRANTED) {
    //           console.log("Location permission granted.");
    //           callGetGeoLocation();
    //         } else {
    //           console.log("Location permission denied.");
    //         }
    //       },
    //       (error) => console.error("Permission request error:", error)
    //     );
    //   } else {
    //     console.log("Location permission denied.");
    //   }
    // }, (error) => console.error("Permission check error:", error));


    navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
      console.log("Permission state:", result.state);
      if (result.state === 'granted') {
          callGetGeoLocation();
      } else if (result.state === 'prompt') {
          console.log("User needs to be asked for location access.");
      } else {
          console.error("Location permission denied.");
      }
    });
  }
};


// Cordova Initialization
const initCordova = () => {
  document.addEventListener(
    'deviceready',
    () => {
      console.log('Cordova is ready');

      if (!window.indexedDB) {
        console.log("IndexedDB is not supported in this environment.");
      } else {
        console.log("IndexedDB is supported.");
        navigator.storage.persist().then((isPersisted) => {
          console.log(isPersisted ? "Storage will persist" : "Storage may be cleared by the OS");
        });
      }

      // Request location permission
      requestLocationPermission();

      renderReactDom();

      // setTimeout(() => {
      //   navigator?.splashscreen?.hide();
      // }, 1000);
    },
    false
  );
};

// Main Initialization
if (window.cordova) {
  //navigator?.splashscreen?.show();
  initCordova();
} else {
  callGetGeoLocation();
  renderReactDom()
} 

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
registerServiceWorker();
getDeviceId();