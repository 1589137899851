import { PAGE_CONSTANT, runAnalytic_v2 } from "../services/functions";

const DetailsUserInfo = ({ hideMobile, detailsData, authData }) => {

  const handlePhoneCall = () => {
    // Replace `1234567890` with the actual phone number
    runAnalytic_v2({ categoryToAdd: detailsData?.category?.[0]?.name, authData, dispatch, page: PAGE_CONSTANT.DETAILS_PAGE_CALL_CLICK, producer: detailsData?.id, searchCategoryId: detailsData?.category?.[0]?.id })
    if (window?.cordova && window?.cordova?.InAppBrowser) {
      cordova.InAppBrowser.open(`tel:${detailsData?.mobile}`, "_system");
    } else {
      window.location.href = "tel:" + detailsData?.mobile;
    }
  };

  return (
    <div className="d-flex flex-column text-break bg-light rounded details-user-info-responsive p-2">
      {/* Name */}
      <div className="capitalize mb-3">
        <i className="fa fa-user"></i>
        <span className="px-2">{detailsData?.name}</span>
      </div>

      {/* Address Section */}
      {!hideMobile && (
        <>
          <div className="capitalize d-flex align-items-center mb-3">
            <i className="fa fa-map-marker"></i>
            <div className="px-2">
              {[
                detailsData?.address,
                detailsData?.pincode,
                detailsData?.postOffice?.District,
                detailsData?.postOffice?.Division,
                detailsData?.postOffice?.State,
                detailsData?.postOffice?.Country,
              ]
                .filter(Boolean)
                .join(", ")}
            </div>
          </div>

          {/* Phone Call */}
          <div className="mb-3">
            <i className="fa fa-phone"></i>
            <a href="#" className="px-2" onClick={handlePhoneCall}>
              Call
            </a>
          </div>

          {/* Category */}
          {detailsData?.category?.[0] && (
            <div className="capitalize mb-3">
              <img
                src={detailsData.category[0]?.photo || logo}
                alt={detailsData.category[0]?.name}
                className="category-image"
                onError={(e) => {
                  e.target.src = logo;
                }}
              />
              &nbsp;{detailsData.category[0]?.name}
            </div>
          )}

          {/* About Me */}
          {detailsData?.aboutme && (
            <div className="mb-3">
              <i className="fa fa-info-circle"></i>
              <span className="px-2">{detailsData.aboutme}</span>
            </div>
          )}

          {/* Social Links */}
          {(detailsData?.instalink || detailsData?.fblink) && (
            <div className="mb-3">
              <i className="fa fa-hand-o-right"></i>
              {[
                { link: detailsData?.instalink, icon: "fa-instagram", class: "instagram" },
                { link: detailsData?.fblink, icon: "fa-facebook-f", class: "facebook" },
              ]
                .filter((item) => item.link)
                .map((item, index) => (
                  <a
                    key={index}
                    href="#"
                    rel="noopener noreferrer"
                    className={`social-icon ${item.class} px-2`}
                    onClick={() => handleHrefRedirect(item.link)}
                  >
                    <i className={`fa ${item?.icon}`}></i>
                  </a>
                ))}
            </div>
          )}
        </>
      )}
    </div>

  );
}

export default DetailsUserInfo;
