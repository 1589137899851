import { useState } from "react";
import ErrorMessageFormik from "./ErrorMessageFormik";
import H from "./H";

const CustomInput = ({ title,id,type,placeholder,
    handleChange,name, value, isDisable,handleClick ,autoFocus, onBlur, formikError, formik, preInputText,maxLength, addCssToInput, state }) => {
    const _isDisable = isDisable ? true : false;
    const error = state?.[`error${name}`] || ''

    const [customCss] = useState(preInputText ? "input-group" : "");
    
    const _handleChange = (name) =>{
        return handleChange(name);
    }

    
    return (
        <>
            <H>{title}</H>
            <div className={`${customCss} form-floating mb-3`}>
                {preInputText ? <span className="input-group-text">{preInputText}</span> : ""}
                <input id={id} type={type} className={`${addCssToInput} form-control form-floating bg-light ${state && state[name+"Error"] ? 'border-danger' : ''}`}
                    placeholder={placeholder}
                    onChange={_handleChange(name)} 
                    value={value || ''} disabled={_isDisable}
                    autoComplete="off"
                    onClick={handleClick}
                    autoFocus={autoFocus}
                    onBlur={onBlur}
                    maxLength={maxLength ? maxLength : ''}
                    />
                 <label htmlFor={id}>{placeholder}</label>
                 {formikError ? <ErrorMessageFormik formik={formik} name={name}/> : null}
                 {state && state[name+"Error"] && <small className="text-danger">{state[name+"Error"]}</small>}
            </div>
            
        </>
    );
}

export default CustomInput;
