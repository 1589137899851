import logo from '../logo.png';
import "../loader.css";
import useImageCacheIndexedDb from "../services/useImageCacheIndexedDb";

const CategoryCard = ({category, handleClick }) => {
    const { image, loading } = useImageCacheIndexedDb(category?.photo || logo);

    if (loading) {
        return <div className="category-card my-color-bg-light-1">
                <img src={logo} alt={"loading.."} className="category-image m-2" />
                {/* <div className="loader_image m-4"></div> */}
                <div className="category-name">{category?.name}</div>
              </div>;
    }

    return (
        <div className="category-card my-color-bg-light-1" onClick={() => handleClick(category)}>
          <img src={image} alt={category?.name} className="category-image-home" />
          <div className="category-name">{category?.name}</div>
        </div>
      );
  }
  
  export default CategoryCard;
