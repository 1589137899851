import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { addPostAction } from '../redux/slices/postsSlice';
import { addRatingAction } from '../redux/slices/ratingsSlice';
import CustomInput from '../components/CustomInput';
import CommentModel from '../components/CommentModel';
import Rating from '../components/Rating';
import StarRating from '../components/StarRating';
import { followAction, unfollowAction } from '../redux/slices/usrSlice';
import CustomIcon from '../components/CustomIcon';
import CustomImage from '../components/CustomImage';
import { handleErrorResponse, handleHrefRedirect, PAGE_CONSTANT, runAnalytic_v2 } from '../services/functions';
import logo from '../logo192.png';
import DetailsUserInfo from './DetailsUserInfo';

const DetailsModel = ({ }) => {
  
  const detailsData = useSelector(state => state?.details?.data);
  const authData = useSelector(state => state?.auth?.data);
  const details = useSelector(state => state?.details);

  const dispatch = useDispatch();
  const [state, setState] = useState({ id: '', otherUserId: '', comment: " ", rating: '', postOffice: '' });

  const commentByOthersData = detailsData?.commentByOthers;

  const ratingByOthersData = detailsData?.ratingByOthers || [];
  const ratingByOthersGroupByData = groupBy(ratingByOthersData, rating => rating.rating);
  const alreadyRated = ratingByOthersData.filter((el) => {
    return el.user[0] === authData?.id
  }).length;

  const alreadyFollowing = detailsData?.followers?.find(u => u?.toString() === authData?.id?.toString());
  const hideMobile = detailsData?.category?.find(u => u?.name?.toLowerCase() === "user");

  const handleSubmit = (type, rating) => e => {
    e.preventDefault();

    if (!authData || !authData?.isLoggedIn) {
      handleErrorResponse({ message: "Please login!!" }, dispatch);
      return;
    }

    if (type === "post") {
      dispatch(addPostAction({ id: authData?.id, otherUserId: detailsData?.id, comment: state?.comment?.trim() }));
      setState({ ...state, comment: "" })
    }

    if (type === "rating") {
      dispatch(addRatingAction({ id: authData?.id, otherUserId: detailsData?.id, rating: rating }));
      setState({ ...state, rating: "" })
    }

    if (type === "follow") {
      dispatch(followAction({ id: authData?.id, otherUserId: detailsData?.id }));
    }

    if (type === "unfollow") {
      dispatch(unfollowAction({ id: authData?.id, otherUserId: detailsData?.id }));
    }

  }

  const handleChange = input => e => {
    e.preventDefault();
    setState({ ...state, [input]: e.target.value });
  }

  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  const handlePhoneCall = () => {
    // Replace `1234567890` with the actual phone number
    runAnalytic_v2({categoryToAdd:detailsData?.category?.[0]?.name, authData,dispatch,page:PAGE_CONSTANT.DETAILS_PAGE_CALL_CLICK,producer:detailsData?.id,searchCategoryId:detailsData?.category?.[0]?.id})
    if (window?.cordova && window?.cordova?.InAppBrowser) {
      cordova.InAppBrowser.open(`tel:${detailsData.mobile}`, "_system");
    } else {
      window.location.href = "tel:" + detailsData.mobile;
    }
    
  };

  const handleWhatsApp = () => {
    // Replace `1234567890` with the actual phone number
    window.location.href = "https://api.whatsapp.com/send?phone=" + detailsData.mobile;
  };

  const closeModal = async () => {
    const modalElement = document.getElementById('detailsModal');
    if (modalElement) {
      const modalElementHeader = document.getElementById('header');
      modalElementHeader?.classList?.remove('hidden');
    
      $(modalElement).modal('hide');

      // Remove the modal-backdrop if it exists
      const backdrop = document.querySelector(".modal-backdrop");
      if (backdrop){ backdrop.parentNode.removeChild(backdrop);}
    }
  }

  const openCommentModal = async () => {
    const modalElement = document.getElementById('commentModal');
    if(modalElement){
      $(modalElement).modal('show'); // For Bootstrap 4
      modalElement.focus();
    }
  }

  useEffect(() => {
    console.log('use effect > details model,detailsData')

    if(detailsData?.id){
      runAnalytic_v2({categoryToAdd:detailsData?.category?.[0]?.name, authData,dispatch,page:PAGE_CONSTANT.DETAILS_PAGE,producer:detailsData?.id,searchCategoryId:detailsData?.category?.[0]?.id})
    }

    return ()=>{ console.log('return > details modal')}
  },[detailsData?.id])

  // Main UI when selectedUser is available
  if (detailsData?.id) {
    return (
      <>
        <div className="modal fade" id="detailsModal" role="dialog" aria-labelledby="detailsModalLabel" aria-hidden="true" tabIndex="-1">
          <div className="modal-dialog modal-fullscreen" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" onClick={closeModal}>
                  <span aria-hidden="true" className="h5 text-danger"><b>&times;</b></span>
                </button>
                <div className='text-capitalize col-4 text-truncate text-center'>{detailsData?.category?.[0]?.name}</div>
                <div>&nbsp;</div>
              </div>
              <div className="modal-body">
                <div className="main-container pt-0">
                  <div className="d-flex flex-column align-items-center">
                    {!details.loading && !details.appErr && !details.serverErr ? (<>
                      <div className="mt-3 mb-3 animated-border">
                        {/* <CustomImage className="circle" src="https://picsum.photos/300/300"/> */}
                        <CustomImage state={{ image: detailsData?.profilePhoto, name: detailsData.name }} className={"detail_page__image"} size={"18rem"} />
                      </div>

                      {(authData?.id !== detailsData?.id && alreadyFollowing && !hideMobile) ?
                        (<p>
                          <button className="btn btn-outline-warning mb-3" onClick={handleSubmit("unfollow")}>Unfollow</button>
                        </p>) : ''}

                      {(authData?.id !== detailsData?.id && !alreadyFollowing && !hideMobile) ?
                        (<p>
                          <button className="btn btn-outline-info mb-3" onClick={handleSubmit("follow")}>Follow</button></p>
                        ) : ''}

                      <DetailsUserInfo hideMobile={hideMobile} detailsData={detailsData} authData={authData}/>

                      {!hideMobile ? (<Rating ratingByOthersGroupByData={ratingByOthersGroupByData} ratingByOthersData={ratingByOthersData} />) : ''}
                      <button type="button" className="btn btn-outline-primary mb-3" disabled={commentByOthersData?.length < 1} onClick={openCommentModal}>
                        View reviews({commentByOthersData?.length})
                      </button>

                      <CommentModel commentByOthersData={commentByOthersData} />
                      {!hideMobile && alreadyRated < 1 && authData?.id !== detailsData?.id ? (<StarRating handleSubmit={handleSubmit} />) : ''}

                      {/* <CustomInput title="" id="ratingId" type="number" placeholder="Enter rating" handleChange={handleChange} name="rating" value={state.rating}/> */}
                      {/* <button className="btn btn-outline-primary mb-3" onClick={handleSubmit("rating")}>Post ratings</button> */}

                      {authData?.id !== detailsData?.id && !hideMobile ? (<>
                        <CustomInput title="Post comments" id="postId" type="text" placeholder="type comment here..." handleChange={handleChange} name="comment" value={state.comment} />
                        <button className="btn btn-outline-primary mb-3" onClick={handleSubmit("post")} disabled={!state.comment}>Post comments</button>
                      </>) : ''}
                    </>) : ''}

                    {!details.loading && (details.appErr || details.serverErr) ? (<div>Seems some issue, please try again later!!</div>) : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }


  // Render fallback UI when selectedUser is not present
  if (!detailsData?.id) {
    return (
      <div className="modal fade" id="detailsModal" role="dialog" aria-labelledby="detailsModalLabel" aria-hidden="true" tabIndex="-1">
        <div className="modal-dialog modal-fullscreen" role="document">
          <div className="modal-content">
            <div className="modal-header">
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DetailsModel;
